import {
    getElementOrParentByClass,
    removeAllFromClass,
} from '../helpers/dom-helpers';
const switcherClass = 'js-lang-switcher';
const activeClass = 'is-active';

const clickedOutside = (elem, event) => {
    return !elem.contains(event.target);
};

document.addEventListener('click', function (e) {
    let domElem = getElementOrParentByClass(e, switcherClass);

    if (domElem) {
        domElem.classList.toggle(activeClass);
    } else {
        const activeSwitcher = document.querySelector(
            `.${switcherClass}.${activeClass}`
        );
        if (activeSwitcher && clickedOutside(activeSwitcher, e)) {
            activeSwitcher.classList.remove(activeClass);
        }
    }
});

document.addEventListener('scroll', function (e) {
    removeAllFromClass(switcherClass, activeClass);
});
